
import { defineComponent, reactive } from "vue";
import { IonContent, IonIcon } from "@ionic/vue";
import { close } from "ionicons/icons";
export default defineComponent({
  name: "TermsModal",
  props: {
    term: Object,
    dismiss: Function,
  },
  setup(props: any) {
    const icons = reactive({
      close,
    });
    return {
      props,
      icons,
    };
  },
  components: {
    IonContent,
    IonIcon,
  },
});
