import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-lg-12" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, { class: "whiteBackground ion-padding" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("a", {
              href: "javascript:void(0)",
              class: "close ion-float-end",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.props.dismiss()))
            }, [
              _createVNode(_component_ion_icon, {
                icon: _ctx.icons.close,
                color: "danger"
              }, null, 8, ["icon"])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", {
        class: "content",
        innerHTML: _ctx.props.term.content
      }, null, 8, _hoisted_4)
    ]),
    _: 1
  }))
}