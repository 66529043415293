
import {
	IonPage,
	IonContent,
	IonItem,
	IonLabel,
	IonIcon,
	IonInput,
	IonSelect,
	IonSelectOption,
	IonRadioGroup,
	IonRadio,
	IonImg,
	IonCheckbox,
	IonList,
	IonText,
	IonButton,
	modalController,
} from "@ionic/vue";
import { defineComponent, ref, watch, computed } from "vue";
import {
	personOutline,
	callOutline,
	mapOutline,
	locateOutline,
	pencilOutline,
	informationCircleOutline,
} from "ionicons/icons";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useGlobalFunctions } from "@/libs/globalFunctions";
import { useLocalStorage } from "@/libs/localStorage";
import settingsApp from "@/libs/settings";
import MainHeader from "../components/MainHeader.vue";
import MainFooter from "../components/MainFooter.vue";
import TermsModal from "../components/TermsModal.vue";
import httpApp from "@/libs/http";
import cartApp from "@/libs/cart";
import { useStore } from "@/store";
import valid from "card-validator";
import { Collapse } from "bootstrap";
import { Geolocation } from "@ionic-native/geolocation";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { GoogleMap, Marker, CustomControl } from "vue3-google-map";
import { LMap, LTileLayer, LMarker, LControl } from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";

import { event as gtagEvent, purchase as gtagPurchase } from "vue-gtag";

export default defineComponent({
	name: "Checkout",
	setup() {
		const { t, te, tm } = useI18n();
		const {
			createLoading,
			openToast,
			formatNumberLocale,
		} = useGlobalFunctions();
		const { getLocalStorageData } = useLocalStorage();
		const router = useRouter();
		const storeX = useStore();

		const terms = ref([]);
		const termsApproved = ref([]);

		const fastCheckout = ref(true);

		const currentStep = ref("address");
		const allowedSteps = ref({
			address: false,
			wallet: false,
			loyalty: false,
			shippingmethod: false,
			paymentmethod: false,
			review: false,
		});
		const icons = ref({
			personOutline,
			callOutline,
			mapOutline,
			locateOutline,
			pencilOutline,
			informationCircleOutline,
		});
		const walletNeeded = ref(false);
		const useWallet = ref(0);
		const walletBalance = ref(0);
		const loyaltyNeeded = ref(false);
		const useLoyalty = ref(0);
		const usedPoints = ref(0);
		const pointsValue = ref(0);
		const couponCode = ref("");

		const monthsNos = ref({
			1: "01",
			2: "02",
			3: "03",
			4: "04",
			5: "05",
			6: "06",
			7: "07",
			8: "08",
			9: "09",
			10: "10",
			11: "11",
			12: "12",
		});
		const currentYear = ref(new Date().getFullYear());

		const quote = ref();
		const cartItems = ref([]);
		const totals = ref({
			subtotal: 0,
			deliveryFees: 0,
			discount: 0,
			wallet: 0,
			offer: 0,
			loyalty: 0,
			paymentFees: 0,
			grandTotal: 0,
		});

		const openStep = (step) => {
			const element = document.getElementById(
				"checkoutStep-" + step
			) as HTMLElement;
			const bsCollapse = new Collapse(element);
			bsCollapse.show();
			currentStep.value = step;
			allowedSteps.value[step] = true;
		};

		const domainLangUrl = window.location.origin;
		const isAE = domainLangUrl.includes("ae.yallatager");
		const countryCode = isAE ? "AE" : "EG";

		const addingNewAddress = ref(false);
		const addresses = ref([]);
		const billingAddress = ref({
			entity_id: "",
			address_label: "",
			firstname: storeX.state.customerData.account_data.first_name,
			lastname: storeX.state.customerData.account_data.last_name,
			telephone: storeX.state.customerData.account_data.phone,
			street: "",
			region: "",
			region_id: "",
			city: "",
			city_id: "",
			country_id: countryCode,
			epowerbackend_lat: null,
			epowerbackend_lng: null,
			save_in_address_book: 1,
		});
		const billingAddressRules = {
			firstname: { required },
			lastname: { required },
			telephone: { required },
			street: { required },
			region_id: { required },
			city: { required },
		};

		const vBillingAddress = useVuelidate(
			billingAddressRules,
			billingAddress
		) as any;

		const showMap = ref(false);
		const addressPosition = ref({ lat: null, lng: null });
		const mapService = ref(getLocalStorageData("mapService"));
		const addressGoogleApiKey = ref(getLocalStorageData("addressGoogleApiKey"));

		const getPosition = (userClicked = false) => {
			Geolocation.getCurrentPosition({
				timeout: 3000,
				enableHighAccuracy: true,
			})
				.then((resp) => {
					if (userClicked) {
						addressPosition.value.lat = Number(resp.coords.latitude);
						addressPosition.value.lng = Number(resp.coords.longitude);
						billingAddress.value.epowerbackend_lat = Number(
							resp.coords.latitude
						);
						billingAddress.value.epowerbackend_lng = Number(
							resp.coords.longitude
						);
					}
					showMap.value = false;
					setTimeout(() => {
						showMap.value = true;
					}, 5);
				})
				.catch((error) => {
					addressPosition.value.lat = null;
					addressPosition.value.lng = null;
					openToast(t("errorMsgs.failPosition"), "warning");
					showMap.value = true;
					console.log("Error getting location", error);
				});
		};
		const addNewAddress = () => {
			addingNewAddress.value = true;
			billingAddress.value["entity_id"] = "";
			billingAddress.value["address_label"] = "";
			billingAddress.value["firstname"] =
				storeX.state.customerData.account_data.first_name;
			billingAddress.value["lastname"] =
				storeX.state.customerData.account_data.last_name;
			billingAddress.value["telephone"] =
				storeX.state.customerData.account_data.phone;
			billingAddress.value["street"] = "";
			billingAddress.value["region_id"] = "";
			billingAddress.value["region"] = "";
			billingAddress.value["city"] = "";
			billingAddress.value["city_id"] = "";
			billingAddress.value["epowerbackend_lat"] = null;
			billingAddress.value["epowerbackend_lng"] = null;
			billingAddress.value["save_in_address_book"] = 1;
			getPosition();
		};

		const mapClicked = (event) => {
			if (Object.prototype.hasOwnProperty.call(event, "latlng")) {
				addressPosition.value.lat = Number(event.latlng.lat);
				addressPosition.value.lng = Number(event.latlng.lng);
				billingAddress.value.epowerbackend_lat = Number(event.latlng.lat);
				billingAddress.value.epowerbackend_lng = Number(event.latlng.lng);
				showMap.value = false;
				setTimeout(() => {
					showMap.value = true;
				}, 5);
			}
		};

		const addressDragend = (event) => {
			if (mapService.value == "google_maps") {
				addressPosition.value.lat = Number(event.latLng.lat());
				addressPosition.value.lng = Number(event.latLng.lng());
				billingAddress.value.epowerbackend_lat = Number(event.latLng.lat());
				billingAddress.value.epowerbackend_lng = Number(event.latLng.lng());
			} else if (mapService.value == "osm_leaflet") {
				addressPosition.value.lat = Number(event.target._latlng.lat);
				addressPosition.value.lng = Number(event.target._latlng.lng);
				billingAddress.value.epowerbackend_lat = Number(
					event.target._latlng.lat
				);
				billingAddress.value.epowerbackend_lng = Number(
					event.target._latlng.lng
				);
			}
		};

		const setBillingAddress = (addressId) => {
			addingNewAddress.value = false;
			const selectedAddress = addresses.value.filter((obj) => {
				return obj.entity_id === addressId;
			})[0] as any;
			if (!selectedAddress) {
				return;
			}
			billingAddress.value["entity_id"] = selectedAddress.entity_id;
			billingAddress.value["address_label"] = selectedAddress.address_label;
			billingAddress.value["firstname"] = selectedAddress.firstname;
			billingAddress.value["lastname"] = selectedAddress.lastname;
			billingAddress.value["telephone"] = selectedAddress.telephone;
			billingAddress.value["street"] = selectedAddress.street;
			billingAddress.value["region_id"] = selectedAddress.region_id;
			billingAddress.value["city"] = selectedAddress.city;
			billingAddress.value["city_id"] = selectedAddress.city_id;
			billingAddress.value["epowerbackend_lat"] =
				selectedAddress.epowerbackend_lat;
			billingAddress.value["epowerbackend_lng"] =
				selectedAddress.epowerbackend_lng;
			addressPosition.value.lat = Number(selectedAddress.epowerbackend_lat);
			addressPosition.value.lng = Number(selectedAddress.epowerbackend_lng);
		};

		const useExistedAddress = () => {
			addingNewAddress.value = true;
			if (addresses.value.length > 0) {
				const selectedAddress = addresses.value.filter((obj) => {
					return obj.is_default_shipping === 1;
				})[0] as any;
				setBillingAddress(selectedAddress.entity_id);
			}
		};

		const addressText = (address) => {
			let text = address.formated_oneline;
			if (address.address_label) {
				text = address.address_label;
			}
			if (Object.prototype.hasOwnProperty.call(address, "formated_oneline")) {
				text += ": " + address.formated_oneline;
			}
			return text;
		};
		const paymentMethodText = computed(() => {
			if (paymentMethods.value) {
				const paymentMethod = paymentMethods.value.filter((obj) => {
					return obj.code === selectedPaymentMethod.value;
				});
				return paymentMethod[0].title || selectedPaymentMethod.value;
			}
			return selectedPaymentMethod.value;
		});

		const stateCities = ref([]);
		const changeCityAction = (cityId: any) => {
			billingAddress.value.city_id = cityId;
			for (let i = 0; i < stateCities.value.length; i++) {
				const city = stateCities.value[i];
				if (city.id == billingAddress.value.city_id) {
					billingAddress.value.city = city.name;
				}
			}
		};

		const getStateName = () => {
			if (storeX.state.countryStates) {
				for (let i = 0; i < storeX.state.countryStates.length; i++) {
					const state = storeX.state.countryStates[i];
					if (state.id == billingAddress.value.region_id) {
						billingAddress.value.region = state.name;
						return state.name;
					}
				}
			}
		};

		const changeStateAction = (selectedState: any) => {
			billingAddress.value.region_id = selectedState;
			getStateName();
			if (selectedState != "0") {
				httpApp
					.sendPostRequest(settingsApp.getEndpointUrl("getCitiesByState"), {
						store_view: getLocalStorageData("storeCode"),
						state_id: selectedState,
					})
					.then(async (response: any) => {
						if (response.success) {
							stateCities.value = response.items;
						}
					})
					.catch((error) => {
						console.log(error);
					});
			}
		};

		const selectedPaymentMethod = ref();
		const paymentMethods = ref();
		const paymentData = ref({
			method: "",
			cc_number: "",
			cc_type: "",
			cc_exp_year: "",
			cc_exp_month: "",
			cc_cid: "",
		});

		const creditCardIcon = ref("./assets/images/cards/other-icon.png");
		watch(
			() => paymentData,
			(val) => {
				const numberValidation = valid.number(val.value.cc_number);
				if (numberValidation.card) {
					if (numberValidation.card.type == "mastercard") {
						paymentData.value.cc_type = "MC";
						creditCardIcon.value = "./assets/images/cards/mastercard-icon.png";
					} else if (numberValidation.card.type == "visa") {
						paymentData.value.cc_type = "VI";
						creditCardIcon.value = "./assets/images/cards/visa-icon.png";
					} else {
						paymentData.value.cc_type = "";
						creditCardIcon.value = "./assets/images/cards/other-icon.png";
					}
				}
			},
			{ deep: true }
		);

		const getAllAddresses = async (setDefaultAddress = true) => {
			const loading = await createLoading(t("pleaseWait"));
			loading.present();
			httpApp
				.sendGetRequest(settingsApp.getEndpointUrl("getAllAddresses"))
				.then((resp: any) => {
					loading.dismiss();
					allowedSteps.value.address = true;
					if (!Object.prototype.hasOwnProperty.call(resp, "error")) {
						if (resp.items.length == 0) {
							addNewAddress();
						} else {
							addresses.value = resp.items;
							let finishedProc = 0;
							for (let i = 0; i < resp.items.length; i++) {
								const item = resp.items[i];
								if (setDefaultAddress && item.is_default_shipping) {
									billingAddress.value["entity_id"] = item.entity_id;
									billingAddress.value["address_label"] = item.address_label;
									billingAddress.value["firstname"] = item.firstname;
									billingAddress.value["lastname"] = item.lastname;
									billingAddress.value["telephone"] = item.telephone;
									billingAddress.value["street"] = item.street;
									billingAddress.value["region_id"] = item.region_id;
									billingAddress.value["city"] = item.city;
									billingAddress.value["city_id"] = item.city_id;
									billingAddress.value["epowerbackend_lat"] =
										item.epowerbackend_lat;
									billingAddress.value["epowerbackend_lng"] =
										item.epowerbackend_lng;
									addressPosition.value.lat = Number(item.epowerbackend_lat);
									addressPosition.value.lng = Number(item.epowerbackend_lng);

									if (fastCheckout.value) {
										saveBillingAddressAction(null);
									}
								}
								finishedProc++;
								if (finishedProc == resp.length) {
									addresses.value = resp;
								}
							}
						}
					} else {
						addNewAddress();
					}
					openStep("address");
				})
				.catch((error) => {
					loading.dismiss();
					console.log(error, "error");
				});
		};

		const selectedShippingMethod = ref();
		const shippingMethods = ref();

		const getShipingMethods = async (loading: any = null) => {
			if (!loading) {
				const loading = await createLoading(t("pleaseWait"));
				loading.present();
			}
			httpApp
				.sendGetRequest(settingsApp.getEndpointUrl("getShipingMethods"))
				.then((resp: any) => {
					if (resp.success) {
						allowedSteps.value.shippingmethod = true;
						shippingMethods.value = resp.items;
						Object.keys(shippingMethods.value).forEach((index) => {
							const shipping = shippingMethods.value[index];
							if (shipping.rates.length == 1) {
								selectedShippingMethod.value = shipping.rates[0].code;
								if (fastCheckout.value) {
									saveShippingMethod();
								}
							}
						});
						openStep("shippingmethod");
					} else {
						if (te("apiMsgs." + resp.error.code)) {
							openToast(t("apiMsgs." + resp.error.code), "danger");
						} else {
							openToast(resp.error.message, "danger");
						}
					}
					loading.dismiss();
				})
				.catch((error) => {
					loading.dismiss();
					console.log(error, "error");
				});
		};

		const getPaymentMethods = async (loading: any = null) => {
			if (!loading) {
				const loading = await createLoading(t("pleaseWait"));
				loading.present();
			}
			httpApp
				.sendGetRequest(settingsApp.getEndpointUrl("getPaymentMethods"))
				.then((resp: any) => {
					if (resp.items.length) {
						allowedSteps.value.paymentmethod = true;
						let respPaymentMethods = resp.items;
						let finishedProc = 0;
						for (let i = 0; i < resp.items.length; i++) {
							if (resp.items[i].code == "free") {
								respPaymentMethods = [resp.items[i]];
							}
							finishedProc++;
							if (finishedProc == resp.items.length) {
								paymentMethods.value = respPaymentMethods;
								if (
									currentStep.value != "paymentmethod" &&
									currentStep.value != "review"
								) {
									openStep("paymentmethod");
								}
							}
						}
					}
					loading.dismiss();
				})
				.catch((error) => {
					loading.dismiss();
					console.log(error, "error");
				});
		};

		const getQuote = () => {
			cartApp.getQuote().then((result: any) => {
				quote.value = result.quote;
				totals.value.subtotal = result.subtotal;
				totals.value.deliveryFees = result.deliveryFees;
				totals.value.paymentFees = result.paymentFees;
				totals.value.discount = result.discount;
				totals.value.wallet = result.wallet;
				totals.value.offer = result.offer;
				totals.value.loyalty = result.loyalty;
				totals.value.grandTotal = result.grandTotal;
			});
		};

		const getQuoteItems = async () => {
			const loading = await createLoading(t("pleaseWait"));
			loading.present();
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("getQuoteItems"), {
					store_view: getLocalStorageData("storeCode"),
				})
				.then((resp: any) => {
					loading.dismiss();
					if (resp.success) {
						const items = resp.items;
						cartItems.value = resp.items;
						const storeCartItems: any = {};
						let finishedProc = 0;
						for (let i = 0; i < items.length; i++) {
							const item = items[i];
							storeCartItems["prd_" + item.product_id] = {
								qty: item.qty,
								item_id: item.item_id,
								item: item,
							};
							finishedProc++;
							if (finishedProc == items.length) {
								storeX.commit("setCartItems", storeCartItems);
							}
						}
					} else {
						storeX.commit("setCartItems", null);
					}
				})
				.catch((error) => {
					loading.dismiss();
					console.log(error);
				});
		};

		const getImageUrl = (product: any) => {
			if (product.image && product.image != "no_selection") {
				return (
					getLocalStorageData("websiteUrl") +
					"media/catalog/product" +
					product.image
				);
			} else if (product.thumbnail && product.thumbnail != "no_selection") {
				return (
					getLocalStorageData("websiteUrl") +
					"media/catalog/product" +
					product.thumbnail
				);
			} else {
				return "assets/images/placeholder.png";
			}
		};

		const itemPriceTxt = (price: any) => {
			const formatRowTotal = Number(price) * 1 + " " + storeX.state.currency;
			return formatRowTotal;
		};

		const saveShippingMethod = async () => {
			const loading = await createLoading(t("pleaseWait"));
			loading.present();
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("setShipingRate"), {
					shipping_method: selectedShippingMethod.value,
				})
				.then(() => {
					gtagEvent("checkout_progress", {
						event_label: "Set Shipping Method",
					});
					if (walletNeeded.value) {
						openStep("wallet");
						loading.dismiss();
					} else {
						getQuote();
						getPaymentMethods(loading);
					}
				})
				.catch((error) => {
					loading.dismiss();
					console.log(error, "error setShipingRate");
				});
		};

		const selectShippingMethod = (event: any) => {
			selectedShippingMethod.value = event.target.value;
			setTimeout(() => {
				saveShippingMethod();
			}, 300);
		};

		const checkLoyalty = async (loading: any = null) => {
			if (!loading) {
				const loading = await createLoading(t("pleaseWait"));
				loading.present();
			}
			httpApp
				.sendGetRequest(settingsApp.getEndpointUrl("isLoyaltyNeededInCheckout"))
				.then((resp: any) => {
					loyaltyNeeded.value = resp.success;
					if (
						resp.success &&
						storeX.state.customerData.account_data.points_balance * 1 > 0
					) {
						openStep("loyalty");
						loading.dismiss();
					} else {
						getPaymentMethods(loading);
					}
				})
				.catch((error) => {
					loading.dismiss();
					console.log(error, "error");
				});
		};

		const saveBillingAddressAction = async (event: any) => {
			if (event) {
				event.preventDefault();
			}
			vBillingAddress.value.$touch();
			if (vBillingAddress.value.$invalid) {
				if (Number(billingAddress.value.entity_id) > 0) {
					openToast(t("checkout.invalidAddressMsg"), "danger");
					router.push("/account/addressbook/" + billingAddress.value.entity_id);
				}
				return false;
			}
			const loading = await createLoading(t("pleaseWait"));
			loading.present();
			const sentAddress = billingAddress.value;
			sentAddress["use_for_shipping"] = 1;
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("saveBillingAddress"), {
					address_id: billingAddress.value.entity_id,
					billing: sentAddress,
				})
				.then((resp: any) => {
					if (!Object.prototype.hasOwnProperty.call(resp, "error")) {
						gtagEvent("checkout_progress", {
							event_label: "Set Billing Address",
						});
						//saveDeliveryTimeAction(new Date(), loading);
						if (quote.value.is_virtual) {
							if (walletNeeded.value) {
								openStep("wallet");
								loading.dismiss();
							} else {
								checkLoyalty(loading);
							}
						} else {
							getShipingMethods(loading);
						}
						// if (quote.value.is_virtual) {
						//   if (walletNeeded.value) {
						//     openStep("wallet");
						//     loading.dismiss();
						//   } else {
						//     checkLoyalty(loading);
						//     //getPaymentMethods(loading);
						//   }
						// } else {
						//   saveShippingMethod(loading);
						// }
					} else {
						loading.dismiss();
						if (te("apiMsgs." + resp.error.code)) {
							openToast(t("apiMsgs." + resp.error.code), "danger");
						} else {
							let message;
							if (Array.isArray(resp.error.message)) {
								message = resp.error.message.join(" - ");
							} else {
								message = resp.error.message;
							}
							openToast(message, "danger");
						}
					}
				})
				.catch((error) => {
					loading.dismiss();
					console.log(error, "error");
				});
		};

		const getPaymentMethodInstruction = () => {
			if (selectedPaymentMethod.value) {
				if (
					Object.prototype.hasOwnProperty.call(selectedPaymentMethod, "data")
				) {
					if (
						Object.prototype.hasOwnProperty.call(
							selectedPaymentMethod.value.data,
							"instructions"
						)
					) {
						return selectedPaymentMethod.value.data.instructions;
					}
				}
			}
		};

		const setWalletAction = async (event: any) => {
			event.preventDefault();
			if (useWallet.value == 0) {
				return;
			}
			const walletUsage = useWallet.value == 1 ? 1 : 0;
			const loading = await createLoading(t("pleaseWait"));
			loading.present();
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("setWalletBalanceToCart"), {
					use_wallet: walletUsage,
				})
				.then((resp: any) => {
					if (resp.success) {
						gtagEvent("checkout_progress", {
							event_label: "Set Wallet",
							value: walletUsage,
						});
						getQuote();
						checkLoyalty(loading);
					} else {
						if (te("apiMsgs." + resp.error.code)) {
							openToast(t("apiMsgs." + resp.error.code), "danger");
						} else {
							openToast(resp.error.message, "danger");
						}
						loading.dismiss();
					}
				})
				.catch((error) => {
					loading.dismiss();
					console.log(error, "error");
				});
		};

		watch(usedPoints, (val) => {
			if (val == 0 || !val) {
				pointsValue.value = 0;
			}
			httpApp
				.sendPostRequest(
					settingsApp.getEndpointUrl("loyaltyProgramGetPointsValue"),
					{
						points: val,
					}
				)
				.then((resp: any) => {
					if (resp.success) {
						pointsValue.value = resp.value * 1;
					} else {
						pointsValue.value = 0;
					}
				})
				.catch((error) => {
					pointsValue.value = 0;
					console.log(error, "error");
				});
		});

		const setLoyaltyAction = async (event) => {
			event.preventDefault();
			if (
				useLoyalty.value == 0 ||
				(useLoyalty.value == 1 && (!usedPoints.value || usedPoints.value == 0))
			) {
				return false;
			}
			const loyaltyUsage = useLoyalty.value == 1 ? 1 : 0;
			const loading = await createLoading(t("pleaseWait"));
			loading.present();
			httpApp
				.sendPostRequest(
					settingsApp.getEndpointUrl("loyaltyProgramSetPointsToCart"),
					{
						use_redeem_points: loyaltyUsage,
						points_to_redeem: usedPoints.value,
					}
				)
				.then((resp: any) => {
					if (resp.success) {
						gtagEvent("checkout_progress", {
							event_label: "Set Loyalty",
							value: loyaltyUsage,
						});
						getQuote();
						getPaymentMethods(loading);
					} else {
						if (te("apiMsgs." + resp.error.code)) {
							openToast(t("apiMsgs." + resp.error.code), "danger");
						} else {
							openToast(resp.error.message, "danger");
						}
						loading.dismiss();
					}
				})
				.catch((error) => {
					loading.dismiss();
					console.log(error, "error");
				});
		};

		const savePaymentAction = async () => {
			if (
				selectedPaymentMethod.value == "ccsave" ||
				selectedPaymentMethod.value == "cybersource_sop" ||
				selectedPaymentMethod.value == "cybersource_soap"
			) {
				if (
					!paymentData.value["cc_number"] ||
					!paymentData.value["cc_exp_year"] ||
					!paymentData.value["cc_exp_month"] ||
					!paymentData.value["cc_cid"]
				) {
					openToast(t("fillRequiredFields"), "danger");
					return false;
				}
				const numberValidation = valid.number(paymentData.value.cc_number);
				if (!numberValidation.isValid) {
					openToast(t("invalidCardNumber"), "danger");
					return false;
				}
			}

			if (!selectedPaymentMethod.value) {
				openToast(t("checkout.selectPaymentMethod"), "danger");
				return false;
			}

			paymentData.value["method"] = selectedPaymentMethod.value;

			const loading = await createLoading(t("pleaseWait"));
			loading.present();
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("setPaymentMethod"), {
					payment: paymentData.value,
				})
				.then((resp: any) => {
					if (resp.success) {
						gtagEvent("checkout_progress", {
							event_label: "Set Payment Method",
						});
						getQuote();
						getQuoteItems();
						openStep("review");
					} else {
						if (te("apiMsgs." + resp.error.code)) {
							openToast(t("apiMsgs." + resp.error.code), "danger");
						} else {
							openToast(resp.error.message, "danger");
						}
					}
					loading.dismiss();
				})
				.catch((error) => {
					loading.dismiss();
					console.log(error, "error");
				});
		};

		const selectPaymentMethod = (event: any) => {
			selectedPaymentMethod.value = event.target.value;
			paymentData.value["method"] = event.target.value;
			if (
				selectedPaymentMethod.value != "ccsave" &&
				selectedPaymentMethod.value != "cybersource_sop" &&
				selectedPaymentMethod.value != "cybersource_soap"
			) {
				savePaymentAction();
			}
		};

		const applayPromo = async (event: any) => {
			event.preventDefault();
			cartApp
				.applyPromoCode(couponCode.value, true)
				.then(() => {
					getQuoteItems();
					getQuote();
					if (currentStep.value != "address") {
						openStep("address");
					}
				})
				.catch((error) => {
					console.log(error, "applayPromo");
				});
		};

		const getTerms = () => {
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("getSalesterms"), {
					store_id: getLocalStorageData("storeCode"),
				})
				.then((resp: any) => {
					if (resp.success) {
						resp.items.forEach(() => {
							termsApproved.value.push({
								approved: true,
								error: false,
							});
						});
						terms.value = resp.items;
					}
				})
				.catch((error) => {
					terms.value = [];
					console.log(error);
				});
		};

		const openTerms = async (term) => {
			const modal = await modalController.create({
				component: TermsModal,
				cssClass: "addressModal",
				componentProps: {
					term: term,
					dismiss: () => {
						modal.dismiss();
					},
				},
			});
			return modal.present();
		};

		const checkTerms = () => {
			return new Promise(function(resolve) {
				if (terms.value.length == 0) {
					resolve(true);
				} else {
					let finishedProc = 0;
					let approved = true;
					termsApproved.value.forEach((element) => {
						if (!element.approved) {
							element.error = true;
							approved = false;
						} else {
							element.error = false;
						}
						finishedProc++;
						if (finishedProc == termsApproved.value.length) {
							if (approved) {
								resolve(true);
							} else {
								resolve(false);
							}
						}
					});
				}
			});
		};

		const placeOrder = async () => {
			if (!storeX.state.cartItemsValid) {
				router.replace("/cart");
			}
			const approved = await checkTerms();

			if (!approved) {
				return;
			}

			const loading = await createLoading(t("pleaseWait"));
			loading.present();
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("placeOrder"), {
					payment: paymentData.value,
				})
				.then((resp: any) => {
					storeX.commit("setCartItems", null);
					getQuote();
					getQuoteItems();
					if (resp.success) {
						//gtagEvent("purchase");
						gtagPurchase({
							transaction_id: resp.increment_id,
							affiliation: "Website",
							value: Number(totals.value.grandTotal),
							shipping: Number(totals.value.deliveryFees),
						});
						if (!resp.redirect) {
							openToast(
								t("checkout.orderThanksMessage") + resp.increment_id,
								"success"
							);
							router.replace("/account/orders");
						} else {
							//let redirectUrl = resp.redirect;
							//redirectUrl += "?increment_id=" + resp.increment_id;

							if (selectedPaymentMethod.value == "egyprotech_mpgshc") {
								router.push({
									name: "payprocessing",
									params: {
										payment_method: selectedPaymentMethod.value,
										order_no: resp.increment_id,
									},
								});
							} else if (selectedPaymentMethod.value == "egyprotech_stryve") {
								router.push({
									name: "payprocessing",
									params: {
										payment_method: selectedPaymentMethod.value,
										order_no: resp.increment_id,
									},
								});
							} else if (selectedPaymentMethod.value == "egyprotech_paytabs") {
								router.push({
									name: "payprocessing",
									params: {
										payment_method: selectedPaymentMethod.value,
										order_no: resp.increment_id,
									},
								});
							} else if (selectedPaymentMethod.value == "egyprotech_fawry") {
								router.push({
									name: "payprocessing",
									params: {
										payment_method: selectedPaymentMethod.value,
										order_no: resp.increment_id,
									},
								});
							}
						}
					} else {
						if (te("apiMsgs." + resp.error.code)) {
							openToast(t("apiMsgs." + resp.error.code), "danger");
						} else {
							const pattern = /No\.(\d+)/;
							const match = resp.error.message.match(pattern);
							if (match) {
								const orderNo = match[1];
								if (selectedPaymentMethod.value == "egyprotech_mpgshc") {
									router.push({
										name: "payprocessing",
										params: {
											payment_method: selectedPaymentMethod.value,
											order_no: orderNo,
										},
									});
								} else if (selectedPaymentMethod.value == "egyprotech_stryve") {
									router.push({
										name: "payprocessing",
										params: {
											payment_method: selectedPaymentMethod.value,
											order_no: orderNo,
										},
									});
								} else if (
									selectedPaymentMethod.value == "egyprotech_paytabs"
								) {
									router.push({
										name: "payprocessing",
										params: {
											payment_method: selectedPaymentMethod.value,
											order_no: orderNo,
										},
									});
								} else {
									openToast(resp.error.message, "danger");
									router.replace("/cart");
								}
							} else {
								openToast(resp.error.message, "danger");
								router.replace("/cart");
							}
						}
					}
					loading.dismiss();
				})
				.catch((error) => {
					storeX.commit("setCartItems", null);
					getQuote();
					getQuoteItems();
					loading.dismiss();
					console.log(error, "error");
				});
		};

		const checkWallet = () => {
			httpApp
				.sendGetRequest(settingsApp.getEndpointUrl("isWalletNeededInCheckout"))
				.then((resp: any) => {
					walletNeeded.value = resp.needed;
					if (resp.needed) {
						walletBalance.value = resp.balance;
					}
				})
				.catch((error) => {
					console.log(error, "error");
				});
		};

		function ionViewWillEnter() {
			currentStep.value = "address";
			if (!storeX.state.cartItemsValid) {
				router.replace("/cart");
			} else {
				gtagEvent("begin_checkout");
			}
			getTerms();
			getAllAddresses();
			getQuote();
			checkWallet();
		}

		return {
			t,
			te,
			tm,
			storeX,
			currentStep,
			allowedSteps,
			walletNeeded,
			quote,
			useWallet,
			walletBalance,
			setWalletAction,
			loyaltyNeeded,
			useLoyalty,
			usedPoints,
			pointsValue,
			setLoyaltyAction,
			icons,
			ionViewWillEnter,
			addresses,
			setBillingAddress,
			addNewAddress,
			useExistedAddress,
			addressText,
			paymentMethodText,
			addingNewAddress,
			billingAddress,
			vBillingAddress,
			stateCities,
			changeCityAction,
			changeStateAction,
			showMap,
			mapService,
			addressGoogleApiKey,
			addressPosition,
			getPosition,
			mapClicked,
			addressDragend,
			monthsNos,
			currentYear,
			cartItems,
			totals,
			selectedPaymentMethod,
			paymentMethods,
			paymentData,
			creditCardIcon,
			getPaymentMethodInstruction,
			saveBillingAddressAction,
			getImageUrl,
			itemPriceTxt,
			selectShippingMethod,
			selectedShippingMethod,
			shippingMethods,
			savePaymentAction,
			selectPaymentMethod,
			applayPromo,
			couponCode,
			saveShippingMethod,
			placeOrder,
			formatNumberLocale,
			terms,
			openTerms,
			termsApproved,
			checkTerms,
			fastCheckout,
		};
	},
	components: {
		IonPage,
		IonContent,
		MainHeader,
		MainFooter,
		IonItem,
		IonLabel,
		IonIcon,
		IonInput,
		IonSelect,
		IonSelectOption,
		IonRadioGroup,
		IonRadio,
		IonImg,
		IonCheckbox,
		IonList,
		IonText,
		IonButton,
		GoogleMap,
		Marker,
		CustomControl,
		LMap,
		LTileLayer,
		LMarker,
		LControl,
	},
});
